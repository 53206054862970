import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, filter, map, switchMap, take, tap } from 'rxjs/operators';

import { AppUserRole } from '@yuno/api/interface';

import { AccessTokenStorageService } from '../services/access-token-storage.service';
import { AppAuthService } from '../services/app-auth.service';
import { AuthService, REDIRECT } from '../services/auth.service';
import { JwtHelperService } from '../services/jwt-helper.service';
import { authActions } from './auth.actions';
import { AuthTokens, UserLogin } from './auth.models';

export const onLogin$ = createEffect(
	(
		actions$ = inject(Actions),
		authService = inject(AuthService),
		accessTokenStorage = inject(AccessTokenStorageService)
	) => {
		return actions$.pipe(
			ofType(authActions.login),
			exhaustMap(credentials =>
				authService.login(credentials.email, credentials.password).pipe(
					map((auth: UserLogin) => {
						// save token
						accessTokenStorage.saveAccessToken(auth.accessToken);

						// login success trigger
						return authActions.loginSuccess({ user: auth.user });
					}),
					catchError(error => of(authActions.loginFailure({ error })))
				)
			)
		);
	},
	{ functional: true }
);

export const onLoginSuccess$ = createEffect(
	(actions$ = inject(Actions), authService = inject(AuthService)) => {
		return actions$.pipe(
			ofType(authActions.loginSuccess),
			map(() => {
				authService.authRefreshInterval();
				return authActions.loginComplete();
			})
		);
	},
	{ functional: true }
);

export const onLoginRedirect$ = createEffect(
	(
		actions$ = inject(Actions),
		authService = inject(AuthService),
		router = inject(Router),
		redirect = inject(REDIRECT),
		platformId = inject(PLATFORM_ID)
	) => {
		return actions$.pipe(
			ofType(authActions.loginRedirect),
			tap(() => {
				if (isPlatformBrowser(platformId)) {
					const queryParams = window.location.search;
					let returnUrlParams = new URLSearchParams(queryParams).get('returnUrl');

					if (returnUrlParams && !returnUrlParams.includes('auth')) {
						if (returnUrlParams.charAt(0) !== '/') {
							returnUrlParams = '/' + returnUrlParams;
						}

						router.navigateByUrl(returnUrlParams);
						return;
					}

					if (authService.authResolveParams) {
						router.navigate([...authService.authResolveParams, ...redirect]);
						return;
					}

					router.navigate(redirect);
				}
			})
		);
	},
	{ dispatch: false, functional: true }
);

export const onSetPasswordToken$ = createEffect(
	(actions$ = inject(Actions), authService = inject(AuthService)) => {
		return actions$.pipe(
			ofType(authActions.setPasswordToken),
			exhaustMap(data =>
				authService.validateResetToken(data.token).pipe(
					map(data => {
						if (!data.success) {
							return authActions.setPasswordTokenFailure({ error: 'invalid' });
						}

						return authActions.setPasswordTokenSuccess();
					}),
					catchError(error => {
						return of(authActions.setPasswordTokenFailure({ error }));
					})
				)
			)
		);
	},
	{ functional: true }
);

export const onSetPassword$ = createEffect(
	(actions$ = inject(Actions), authService = inject(AuthService)) => {
		return actions$.pipe(
			ofType(authActions.setPassword),
			exhaustMap(data =>
				authService.setPassword(data.resetToken, data.password).pipe(
					map(data => {
						if (!data.success) {
							return authActions.setPasswordFailure({ error: 'invalid' });
						}

						return authActions.setPasswordSuccess();
					}),
					catchError(error => of(authActions.setPasswordFailure({ error })))
				)
			)
		);
	},
	{ functional: true }
);

export const onSetPasswordSuccess$ = createEffect(
	(actions$ = inject(Actions)) => {
		return actions$.pipe(
			ofType(authActions.setPasswordSuccess),
			map(() => {
				return authActions.setPasswordComplete();
			})
		);
	},
	{ functional: true }
);

export const onSetPasswordComplete$ = createEffect(
	(store = inject(Store), actions$ = inject(Actions), authService = inject(AuthService)) => {
		return actions$.pipe(
			ofType(authActions.setPasswordComplete),
			map(() => {
				setTimeout(() => {
					// redirect to login
					authService.redirectToLogin();
					store.dispatch(authActions.setPasswordPending());
				}, 3500);
			})
		);
	},
	{
		dispatch: false,
		functional: true
	}
);

export const onCreateUser$ = createEffect(
	(actions$ = inject(Actions), authService = inject(AuthService)) => {
		return actions$.pipe(
			ofType(authActions.createUser),
			exhaustMap(data =>
				authService
					.createUser(data.resetToken, data.displayName, data.language, data.password)
					.pipe(
						map(data => {
							if (!data.success) {
								return authActions.createUserFailure({ error: 'invalid' });
							}

							return authActions.createUserSuccess();
						}),
						catchError(error => of(authActions.createUserFailure({ error })))
					)
			)
		);
	},
	{ functional: true }
);

export const onCreateUserSuccess$ = createEffect(
	(actions$ = inject(Actions)) => {
		return actions$.pipe(
			ofType(authActions.createUserSuccess),
			map(() => {
				return authActions.createUserComplete();
			})
		);
	},
	{ functional: true }
);

export const onCreateUserComplete$ = createEffect(
	(store = inject(Store), actions$ = inject(Actions), authService = inject(AuthService)) => {
		return actions$.pipe(
			ofType(authActions.createUserComplete),
			map(() => {
				setTimeout(() => {
					// redirect to login
					authService.redirectToLogin();
					store.dispatch(authActions.createUserPending());
				}, 3500);
			})
		);
	},
	{
		dispatch: false,
		functional: true
	}
);

export const onAccessToken$ = createEffect(
	(
		actions$ = inject(Actions),
		authService = inject(AuthService),
		jwt = inject(JwtHelperService),
		accessTokenStorage = inject(AccessTokenStorageService)
	) => {
		return actions$.pipe(
			ofType(authActions.accessTokenRefresh),
			switchMap(() => {
				const expiring = jwt.tokenExpiringSoon(accessTokenStorage.getAccessToken());

				if (!expiring) {
					return of(authActions.accessTokenSuccess());
				}

				return authService.refreshAuth().pipe(
					take(1),
					map((auth: AuthTokens) => {
						accessTokenStorage.saveAccessToken(auth.accessToken);
						return authActions.accessTokenSuccess();
					}),
					catchError(() => of(authActions.accessTokenFailure()))
				);
			})
		);
	},
	{ functional: true }
);

export const onFailure$ = createEffect(
	(actions$ = inject(Actions)) => {
		return actions$.pipe(
			ofType(authActions.loginFailure, authActions.accessTokenFailure),
			map(() => {
				return authActions.logout();
			})
		);
	},
	{ functional: true }
);

export const onLogout$ = createEffect(
	(
		actions$ = inject(Actions),
		authService = inject(AuthService),
		accessTokenStorage = inject(AccessTokenStorageService)
	) => {
		return actions$.pipe(
			ofType(authActions.logout),
			tap(() => {
				authService.removeAuthRefresh();
				accessTokenStorage.removeTokens();
				authService.redirectToLogin();
			}),
			map(() => authActions.loginPending())
		);
	},
	{ functional: true }
);

export const onrequestPassword$ = createEffect(
	(actions$ = inject(Actions), authService = inject(AuthService)) => {
		return actions$.pipe(
			ofType(authActions.requestPassword),
			exhaustMap(credentials =>
				authService.requestPassword(credentials.email).pipe(
					filter(e => !!e),
					map(() => authActions.requestPasswordSuccess()),
					catchError(error => of(authActions.requestPasswordFailed({ error })))
				)
			)
		);
	},
	{ functional: true }
);

export const onAppRoleVerify$ = createEffect(
	(actions$ = inject(Actions), appAuthService = inject(AppAuthService)) => {
		return actions$.pipe(
			ofType(authActions.appRoleVerify),
			exhaustMap(approle =>
				appAuthService.verifyAppRole(approle.appId).pipe(
					map((auth: AppUserRole) => {
						return authActions.appRoleSuccess({
							appState: {
								appId: auth.appId,
								appRole: auth.role
							}
						});
					}),
					catchError(error => of(authActions.appRoleFailure({ error })))
				)
			)
		);
	},
	{ functional: true }
);

export const onAppRoleSuccess$ = createEffect(
	(actions$ = inject(Actions)) => {
		return actions$.pipe(
			ofType(authActions.appRoleSuccess),
			map(() => {
				return authActions.appRoleComplete();
			})
		);
	},
	{ functional: true }
);
