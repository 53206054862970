import { Injectable } from '@angular/core';

import { JWTTokenDecoded } from '../+state/auth.models';

@Injectable({
	providedIn: 'root'
})
export class JwtHelperService {
	/* based on https://stackoverflow.com/a/38552302 */
	decodeToken(token: string): JWTTokenDecoded {
		const base64Url = token.split('.')[1];
		const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
		const jsonPayload = decodeURIComponent(
			atob(base64)
				.split('')
				.map(function (c) {
					return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
				})
				.join('')
		);

		return JSON.parse(jsonPayload);
	}

	isJWT(token: string): boolean {
		const jwtPattern = /^[A-Za-z0-9-_=]+\.[A-Za-z0-9-_=]+\.[A-Za-z0-9-_.+/=]*$/;
		return jwtPattern.test(token);
	}

	getTokenExpirationDate(token: string): Date | null {
		const decoded: JWTTokenDecoded = this.decodeToken(token);
		if (!decoded || !decoded.exp) {
			return null;
		}

		const date = new Date(0);
		date.setUTCSeconds(decoded.exp);

		return date;
	}

	tokenExpiringSoon(token: string): boolean {
		if (!token || token === '') {
			return true;
		}

		const date = this.getTokenExpirationDate(token);
		if (date === null) {
			return false;
		}

		/* Expires within 5minutes or is expired */
		return date.valueOf() - new Date().valueOf() <= 5 * (1000 * 60);
	}

	isTokenExpired(token: string): boolean {
		if (!token || token === '') {
			return true;
		}
		const date = this.getTokenExpirationDate(token);

		if (date === null) {
			return false;
		}

		return new Date().valueOf() > date.valueOf();
	}
}
