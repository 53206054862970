import { UserRoles, YunoUserRoles } from '@yuno/api/interface';

/**
 * Interface for the 'Auth' data
 */
export interface AuthEntity {
	id: string | number; // Primary ID
	name: string;
}

export const TOKEN_KEY = 'access_token';
export const EXPIRE_KEY = 'expires_at';

export enum StateValidators {
	PENDING = 'PENDING',
	VALIDATING = 'VALIDATING',
	SUCCESS = 'SUCCESS',
	FAILED = 'FAILED'
}

export enum AuthStatus {
	PENDING = 'PENDING',
	VALIDATING = 'VALIDATING',
	VALID = 'VALID',
	INVALID = 'INVALID'
}

export interface AppState {
	appId?: string;
	appRole?: YunoUserRoles;
}

export interface AuthState {
	isAuthenticated: boolean;
	hasAuthenticationError: boolean;
	hasAppRole: boolean;

	user: AuthUser | undefined;
	appState: AppState | undefined;
	appStateStatus: StateValidators;

	accessTokenStatus: AuthStatus;
	authenticationStatus: StateValidators;

	requestResetError: number | undefined;
	requestResetStatus: StateValidators;

	setPasswordStatus: StateValidators;
	setPasswordTokenStatus: StateValidators;

	createUserStatus: StateValidators;
}

export interface SetPassword {
	success: boolean;
	code: string;
	message: string;
}

export interface AuthTokens {
	accessToken: string;
}

export interface UserLogin extends AuthTokens {
	user: AuthUser;
}

export interface JWTTokenDecoded {
	accessTokenId: string;
	exp: number;
	iat: number;
}

export interface AuthUser {
	email: string;
	displayName: string;
	role: UserRoles;

	_id?: string;
	language?: string;
}

export interface RoleGuardStatus {
	appState?: AppState;
	appStateStatus: StateValidators;
}
